import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import GovernmentScheme from './GovtScheme';
import ApplicationForm from './ApplicationFormPage';
import PaymentPage from './PaymentPage';

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<GovernmentScheme />} />
        <Route path="/application" element={<ApplicationForm />} />
        <Route path="/payment" element={<PaymentPage />} />
      </Routes>
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
