// src/benefitData.js
export const benefitsData = [
    {
        id: 'goods',
        title: 'Distribución de Bienes',
        description: 'Este programa proporciona bienes esenciales a individuos y familias necesitadas. Incluye una gama de artículos como alimentos, ropa y productos de higiene. Para calificar, los solicitantes generalmente deben demostrar necesidad económica mediante documentación como declaraciones de ingresos o declaraciones de dificultades. Las solicitudes a menudo se pueden enviar en línea o en los centros de distribución locales. El programa también proporciona una lista de ubicaciones de distribución, fechas y cualquier criterio o restricción de elegibilidad específica. La información sobre los tipos de bienes disponibles y cualquier consideración especial, como la edad o el tamaño de la familia, está incluida.',
        image: 'https://assets.entrepreneur.com/content/3x2/2000/start-wholesale-distribution-business.jpg?format=pjeg&auto=webp&crop=16:9&width=675&height=380' // Reemplazar con la ruta de la imagen real
    },
    {
        id: 'insurance',
        title: 'Seguro',
        description: 'Este beneficio proporciona acceso a varios tipos de cobertura de seguros, incluyendo seguros de salud, vida, discapacidad y automóvil. La elegibilidad para cada tipo de seguro puede variar en función de factores como el nivel de ingresos, el estado de empleo o necesidades específicas. Se proporcionan instrucciones detalladas sobre cómo solicitar cada tipo de seguro, incluyendo la documentación necesaria como registros médicos para el seguro de salud o prueba de empleo para las reclamaciones por discapacidad. El programa también describe cómo presentar reclamaciones, documentos requeridos y fechas o plazos importantes a recordar.',
        image: 'https://prod-sitefinity-library.kappro.com/images/default-source/career-corner/starting-career/insurance-producer_16.jpg?sfvrsn=d94c9803_5' // Reemplazar con la ruta de la imagen real
    },
    {
        id: 'educational',
        title: 'Subvenciones Educativas',
        description: 'Este programa ofrece asistencia financiera para fines educativos, incluyendo subvenciones para estudios de pregrado y posgrado, investigación y formación vocacional. Los criterios de elegibilidad pueden involucrar el rendimiento académico, la necesidad económica o campos de estudio específicos. El proceso de solicitud generalmente requiere la presentación de transcripciones académicas, cartas de recomendación y declaraciones personales. El programa también proporciona información sobre las fechas límite de solicitud, los tipos de subvenciones disponibles (por ejemplo, basadas en necesidades, basadas en méritos) y consejos para mejorar las posibilidades de recibir una subvención. Se incluye orientación sobre la documentación requerida y los procedimientos de solicitud.',
        image: 'https://insightcrime.org/wp-content/uploads/2016/07/en-17-07-21-ElSalvador-Gangs-Schools.png' // Reemplazar con la ruta de la imagen real
    },
    {
        id: 'housing',
        title: 'Asistencia para Vivienda',
        description: 'Este beneficio ayuda a las personas a asegurar una vivienda segura y asequible. Incluye información sobre varios tipos de asistencia para vivienda como subsidios para alquiler, asistencia para compradores de vivienda y programas de vivienda de emergencia. Los requisitos de elegibilidad pueden incluir límites de ingresos, tamaño de la familia o necesidades específicas. El programa describe el proceso de solicitud, cómo encontrar viviendas disponibles y qué documentos se necesitan. También puede haber servicios de apoyo adicionales para la transición a una nueva vivienda, como asesoramiento o asistencia para mudanza.',
        image: 'https://newcumberlandborough.com/wp-content/uploads/2023/07/housing-help.jpeg' // Reemplazar con la ruta de la imagen real
    },
    {
        id: 'employment',
        title: 'Apoyo Laboral',
        description: 'Este programa proporciona servicios de apoyo para personas que buscan empleo. Incluye servicios de colocación laboral, asesoramiento profesional, programas de capacitación y talleres para mejorar las habilidades laborales. Los criterios de elegibilidad pueden variar, y los servicios pueden ofrecerse a través de agencias de empleo locales o programas gubernamentales. El beneficio detalla cómo acceder a estos servicios, incluyendo la información de contacto para agencias de colocación laboral, proveedores de capacitación y otros recursos. El programa también ofrece información sobre procedimientos de solicitud y cualquier requisito de elegibilidad relevante.',
        image: 'https://www.nj.gov/labor/labormarketinformation/assets/images/300x200/GettyImages-1198042442-600x400.jpg' // Reemplazar con la ruta de la imagen real
    },
    {
        id: 'emergency',
        title: 'Alivio de Emergencia',
        description: 'Este beneficio proporciona asistencia inmediata durante emergencias, incluyendo ayuda financiera, refugio temporal y asistencia alimentaria. Describe los tipos de alivio disponibles, como subvenciones financieras de emergencia o vivienda temporal, y cómo solicitar cada tipo. Los criterios de elegibilidad pueden incluir límites de ingresos, declaraciones de desastres o necesidades específicas. El programa proporciona orientación sobre cómo contactar a las agencias de alivio, qué documentación se requiere y qué esperar durante el proceso de solicitud. También se incluye información sobre cualquier condición o requisito que debe cumplirse para recibir asistencia.',
        image: 'https://afsc.org/sites/default/files/2023-01/PB_101589_DSC_0348-adj-1280px.jpg' // Reemplazar con la ruta de la imagen real
    }
];
