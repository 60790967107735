import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Container, Row, Col, Form, Button, Alert, Card, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CBCApplicationForm.css'; // Import custom CSS
import applicationImage from './assets/LogoPagina_Mesa-de-trabajo-1.png';
import { useNavigate } from 'react-router-dom';

const CBCApplicationForm = () => {
  const [currentTime, setCurrentTime] = useState(moment().format('MMMM Do YYYY, h:mm:ss a'));
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format('MMMM Do YYYY, h:mm:ss a'));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const [formData, setFormData] = useState({
    fullName: '',
    dob: '',
    gender: '',
    contactNumber: '',
    email: '',
    nationality: '',
    address: '',
    city: '',
    department: '',
    postalCode: '',
    idType: '',
    idNumber: '',
    occupation: '',
    employmentStatus: '',
    employer: '',
    maritalStatus: '',
    spouseName: '',
    children: '',
    supportingDocuments: null,
    agreeTerms: false,
    confirmInfo: false,
  });

  const [formErrors, setFormErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : type === 'file' ? files[0] : value,
    }));
  };


  const validateForm = () => {
    const errors = {};
    if (!formData.fullName) errors.fullName = 'Full Name is required';
    if (!formData.email) errors.email = 'Email Address is required';
    if (!formData.contactNumber) errors.contactNumber = 'Contact Number is required';
    if (!formData.address) errors.address = 'Address is required';
    if (!formData.city) errors.city = 'City is required';
    if (!formData.department) errors.department = 'Department is required';
    if (!formData.postalCode) errors.postalCode = 'Postal Code is required';
    if (!formData.idType) errors.idType = 'ID Type is required';
    if (!formData.idNumber) errors.idNumber = 'ID Number is required';
    if (!formData.occupation && formData.employmentStatus !== 'Unemployed' && formData.employmentStatus !== 'Student' && formData.employmentStatus !== 'Retired') errors.occupation = 'Occupation is required';
    if (!formData.employmentStatus) errors.employmentStatus = 'Employment Status is required';
    if (!formData.maritalStatus) errors.maritalStatus = 'Marital Status is required';
    if (!formData.agreeTerms) errors.agreeTerms = 'You must agree to the terms and conditions';
    if (!formData.confirmInfo) errors.confirmInfo = 'You must confirm that all information provided is correct';
    return errors;
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setFormSubmitted(true);
    } else {
      setFormErrors(errors);
    }
    if (setShowSuccessModal(true));
    setShowSuccessModal(true);

  };
  
  const handleCloseApplication = () => {
    navigate("/"); // Redirect to the home page

    setShowCloseModal(true);
  };

  const handleConfirmClose = () => {
    // Logic for closing the application (e.g., redirecting to another page)
    setShowCloseModal(false);
  };

  const handleCancelClose = () => {
    setShowCloseModal(false);
  };
  const handleCloseSuccessModal = () => setShowSuccessModal(false);

  const handleProceedToPayment = () => {
    // Redirect to payment page
    window.location.href = '/payment'; // Replace with actual payment page URL
  };
  return (
    <div className="form-background" style={{ backgroundColor: '#f8f9fa', minHeight: '100vh' }}>
 <header className="text-white text-center py-4 position-relative" style={{ backgroundColor: '#003366', padding: '1rem' }}>
      <h1 style={{ fontSize: '1.5rem', margin: '0' }}>Solicitud de Tarjeta de Beneficios de Ciudadanía</h1>
      <p className="lead" style={{ fontSize: '1rem', margin: '0' }}>Por favor, complete el siguiente formulario de solicitud.</p>
      <div className="mt-2" style={{ fontSize: '0.875rem', margin: '0' }}>
        <div className="mt-2" style={{ fontSize: '0.875rem', color: '#d3d3d3', margin: '0' }}>
          <p style={{ margin: '0' }}>{currentTime}</p>
        </div>
      </div>
      <Button
        variant="danger"
        className="position-absolute d-none d-sm-block"
        style={{ top: '10px', right: '10px', fontSize: '0.875rem', padding: '0.375rem 0.75rem' }}
        onClick={handleCloseApplication}
      >
        Cerrar Solicitud
      </Button>
      <Button
        variant="link"
        className="position-absolute d-block d-sm-none"
        style={{ 
          top: '10px', 
          right: '10px', 
          fontSize: '1.5rem', 
          color: '#6c757d',  // Color gris para la cruz
          textDecoration: 'none', 
          padding: '0', 
          border: '2px solid #6c757d',  // Borde gris para la caja
          borderRadius: '8px',  // Esquinas ligeramente redondeadas para la caja
          width: '40px',  // Ancho de la caja
          height: '40px',  // Altura de la caja
          display: 'flex',  // Centrar el contenido horizontal y verticalmente
          alignItems: 'center',  
          justifyContent: 'center',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'  // Opcional: agregar una sombra sutil
        }}
        onClick={handleCloseApplication}
      >
        <span style={{ fontSize: '1.5rem' }}>&times;</span>
      </Button>
    </header>



      <Container className="py-5">
        <Row className="justify-content-center">
          <Col md={12} lg={10}>
            <Card className="border-0 shadow-lg" style={{ borderRadius: '10px', backgroundColor: '#ffffff' }}>
              <Card.Body>
              <div style={{ 
  backgroundColor: '#003366', 
  color: '#ffffff', 
  padding: '10px', 
  textAlign: 'center', 
  borderRadius: '8px' 
}}>
  <h2 className="text-center mb-4">Formulario de Solicitud</h2>
  <p>Complete el formulario a continuación para finalizar su solicitud.</p>

</div>

                <div className="text-center mb-4">
                  <img
                    src={applicationImage}
                    alt="Application Form"
                    className="img-fluid"
                    style={{
                      maxWidth: '20%',
                      height: 'auto',
                      filter: 'brightness(50%)'  // This will make the image darker
                    }}
                  />
                </div>

                {formSubmitted && (
                  <Alert variant="success">Your application has been submitted successfully!</Alert>
                )}

<section style={{
  backgroundColor: '#f1f1f1',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  marginBottom: '20px'
}}>
  <h4 style={{
    color: '#003366',
    borderBottom: '2px solid #003366',
    paddingBottom: '5px',
    marginBottom: '10px'
  }}>Instrucciones</h4>
  <div style={{ marginLeft: '20px' }}>
    <p style={{ fontSize: '14px', lineHeight: '1.6' }}>
      Por favor, lea las siguientes instrucciones cuidadosamente antes de completar el formulario:
    </p>
    <ul style={{
      fontSize: '14px',
      lineHeight: '1.6',
      paddingLeft: '20px', // Alinear correctamente los puntos de la lista
      margin: '0', // Restablecer el margen para evitar espacio adicional
      listStylePosition: 'outside', // Colocar la viñeta fuera del elemento de la lista
      textAlign: 'left' // Asegurar que el texto esté alineado a la izquierda
    }}>
      <li style={{ marginBottom: '5px' }}>Asegúrese de que todos los campos obligatorios estén llenos correctamente.</li>
      <li style={{ marginBottom: '5px' }}>Proporcione información de contacto válida para el seguimiento.</li>
      <li style={{ marginBottom: '5px' }}>Adjunte todos los documentos de respaldo necesarios.</li>
      <li style={{ marginBottom: '5px' }}>Verifique su información antes de enviar el formulario.</li>
    </ul>
  </div>
</section>




<Form onSubmit={handleSubmit} className="application-form">

  {/* Información Personal */}
  <section style={{ marginBottom: '20px', padding: '20px', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
    <h4 style={{ color: '#003366', borderBottom: '2px solid #003366', paddingBottom: '10px' }}>Información Personal</h4>
    <Row className="mb-3">
      <Col md={4}>
        <Form.Group controlId="fullName">
          <Form.Label
            style={{
              fontSize: '1rem',
              fontWeight: 'bold',
              color: '#003366',
              marginBottom: '0.5rem',
              display: 'block'
            }}
          >
            Nombre Completo
          </Form.Label>
          <Form.Control
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            placeholder="Juan Pérez"
            isInvalid={!!formErrors.fullName}
            style={{ borderRadius: '4px', boxShadow: '0 1px 3px rgba(0,0,0,0.1)', padding: '10px' }}
          />
          <Form.Control.Feedback type="invalid">{formErrors.fullName}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col md={4}>
        <Form.Group controlId="dob">
          <Form.Label style={{
            fontSize: '1rem',
            fontWeight: 'bold',
            color: '#003366',
            marginBottom: '0.5rem',
            display: 'block'
          }}>Fecha de Nacimiento</Form.Label>
          <Form.Control
            type="date"
            name="dob"
            value={formData.dob}
            onChange={handleChange}
            style={{ borderRadius: '4px', boxShadow: '0 1px 3px rgba(0,0,0,0.1)', padding: '10px' }}
          />
        </Form.Group>
      </Col>
      <Col md={4}>
        <Form.Group controlId="gender">
          <Form.Label style={{
            fontSize: '1rem',
            fontWeight: 'bold',
            color: '#003366',
            marginBottom: '0.5rem',
            display: 'block'
          }}>Género</Form.Label>
          <Form.Control
            as="select"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            className="custom-select"
            style={{ borderRadius: '4px', boxShadow: '0 1px 3px rgba(0,0,0,0.1)', padding: '10px' }}
          >
            <option value="">Seleccione...</option>
            <option value="Male">Masculino</option>
            <option value="Female">Femenino</option>
            <option value="Other">Otro</option>
            <option value="Prefer not to say">Prefiero no decir</option>
          </Form.Control>
        </Form.Group>
      </Col>
    </Row>

    <Row className="mb-3">
      <Col md={6}>
        <Form.Group controlId="contactNumber">
          <Form.Label style={{
            fontSize: '1rem',
            fontWeight: 'bold',
            color: '#003366',
            marginBottom: '0.5rem',
            display: 'block'
          }}>Número de Contacto</Form.Label>
          <Form.Control
            type="text"
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleChange}
            placeholder="+503 1234 5678"
            isInvalid={!!formErrors.contactNumber}
            style={{ borderRadius: '4px', boxShadow: '0 1px 3px rgba(0,0,0,0.1)', padding: '10px' }}
          />
          <Form.Control.Feedback type="invalid">{formErrors.contactNumber}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group controlId="email">
          <Form.Label style={{
            fontSize: '1rem',
            fontWeight: 'bold',
            color: '#003366',
            marginBottom: '0.5rem',
            display: 'block'
          }}>Correo Electrónico</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="ejemplo@ejemplo.com"
            isInvalid={!!formErrors.email}
            style={{ borderRadius: '4px', boxShadow: '0 1px 3px rgba(0,0,0,0.1)', padding: '10px' }}
          />
          <Form.Control.Feedback type="invalid">{formErrors.email}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Row>
  </section>

  {/* Información de Dirección */}
  <section style={{ marginBottom: '20px', padding: '20px', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
    <h4 style={{ color: '#003366', borderBottom: '2px solid #003366', paddingBottom: '10px' }}>Información de Dirección</h4>
    <Form.Group controlId="address">
      <Form.Label style={{
        fontSize: '1rem',
        fontWeight: 'bold',
        color: '#003366',
        marginBottom: '0.5rem',
        display: 'block'
      }}>Dirección</Form.Label>
      <Form.Control
        type="text"
        name="address"
        value={formData.address}
        onChange={handleChange}
        placeholder="123 Calle Principal"
        isInvalid={!!formErrors.address}
        style={{ borderRadius: '4px', boxShadow: '0 1px 3px rgba(0,0,0,0.1)', padding: '10px' }}
      />
      <Form.Control.Feedback type="invalid">{formErrors.address}</Form.Control.Feedback>
    </Form.Group>
    <Row className="mb-3">
      <Col md={6}>
        <Form.Group controlId="city">
          <Form.Label style={{
            fontSize: '1rem',
            fontWeight: 'bold',
            color: '#003366',
            marginBottom: '0.5rem',
            display: 'block'
          }}>Ciudad</Form.Label>
          <Form.Control
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            placeholder="San Salvador"
            isInvalid={!!formErrors.city}
            style={{ borderRadius: '4px', boxShadow: '0 1px 3px rgba(0,0,0,0.1)', padding: '10px' }}
          />
          <Form.Control.Feedback type="invalid">{formErrors.city}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group controlId="department">
          <Form.Label style={{
            fontSize: '1rem',
            fontWeight: 'bold',
            color: '#003366',
            marginBottom: '0.5rem',
            display: 'block'
          }}>Departamento</Form.Label>
          <Form.Control
            type="text"
            name="department"
            value={formData.department}
            onChange={handleChange}
            placeholder="Departamento"
            isInvalid={!!formErrors.department}
            style={{ borderRadius: '4px', boxShadow: '0 1px 3px rgba(0,0,0,0.1)', padding: '10px' }}
          />
          <Form.Control.Feedback type="invalid">{formErrors.department}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Row>
    <Form.Group controlId="postalCode">
      <Form.Label style={{
        fontSize: '1rem',
        fontWeight: 'bold',
        color: '#003366',
        marginBottom: '0.5rem',
        display: 'block'
      }}>Código Postal</Form.Label>
      <Form.Control
        type="text"
        name="postalCode"
        value={formData.postalCode}
        onChange={handleChange}
        placeholder="12345"
        isInvalid={!!formErrors.postalCode}
        style={{ borderRadius: '4px', boxShadow: '0 1px 3px rgba(0,0,0,0.1)', padding: '10px' }}
      />
      <Form.Control.Feedback type="invalid">{formErrors.postalCode}</Form.Control.Feedback>
    </Form.Group>
  </section>

{/* Nacionalidad */}
<section style={{ marginBottom: '20px', padding: '20px', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
  <h4 style={{ color: '#003366', borderBottom: '2px solid #003366', paddingBottom: '10px' }}>Nacionalidad</h4>
  <Form.Group controlId="nationality">
    <Form.Label style={{ 
      fontSize: '1rem', 
      fontWeight: 'bold', 
      color: '#003366', // Azul marino para mejor visibilidad
      marginBottom: '0.5rem', // Añade espacio debajo de la etiqueta
      display: 'block' // Asegura que la etiqueta ocupe todo el ancho
    }}>Nacionalidad</Form.Label>
    <Form.Control
      type="text"
      name="nationality"
      value={formData.nationality}
      onChange={handleChange}
      placeholder="Salvadoreña"
      style={{ borderRadius: '4px', boxShadow: '0 1px 3px rgba(0,0,0,0.1)', padding: '10px' }}
    />
  </Form.Group>
</section>

{/* Información de Identificación */}
<section style={{ marginBottom: '20px', padding: '20px', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
  <h4 style={{ color: '#003366', borderBottom: '2px solid #003366', paddingBottom: '10px' }}>Información de Identificación</h4>
  <Row className="mb-3">
    <Col md={6}>
      <Form.Group controlId="idType">
        <Form.Label style={{ 
          fontSize: '1rem', 
          fontWeight: 'bold', 
          color: '#003366', // Azul marino para mejor visibilidad
          marginBottom: '0.5rem', // Añade espacio debajo de la etiqueta
          display: 'block' // Asegura que la etiqueta ocupe todo el ancho
        }}>Tipo de Identificación</Form.Label>
        <Form.Control
          as="select"
          name="idType"
          value={formData.idType}
          onChange={handleChange}
          className="custom-select"
          style={{ borderRadius: '4px', boxShadow: '0 1px 3px rgba(0,0,0,0.1)', padding: '10px' }}
        >
          <option value="">Seleccione...</option>
          <option value="DUI">DUI (Documento Único de Identidad)</option>
          <option value="NIT">NIT (Número de Identificación Tributaria)</option>
        </Form.Control>
        {formErrors.idType && <Form.Text className="text-danger">{formErrors.idType}</Form.Text>}
      </Form.Group>
    </Col>
    <Col md={6}>
      <Form.Group controlId="idNumber">
        <Form.Label style={{ 
          fontSize: '1rem', 
          fontWeight: 'bold', 
          color: '#003366', // Azul marino para mejor visibilidad
          marginBottom: '0.5rem', // Añade espacio debajo de la etiqueta
          display: 'block' // Asegura que la etiqueta ocupe todo el ancho
        }}>Número de Identificación</Form.Label>
        <Form.Control
          type="text"
          name="idNumber"
          value={formData.idNumber}
          onChange={handleChange}
          placeholder="12345678-9"
          isInvalid={!!formErrors.idNumber}
          style={{ borderRadius: '4px', boxShadow: '0 1px 3px rgba(0,0,0,0.1)', padding: '10px' }}
        />
        <Form.Control.Feedback type="invalid">{formErrors.idNumber}</Form.Control.Feedback>
      </Form.Group>
    </Col>
  </Row>
</section>

{/* Información de Empleo */}
<section style={{ marginBottom: '20px', padding: '20px', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
  <h4 style={{ color: '#003366', borderBottom: '2px solid #003366', paddingBottom: '10px' }}>Información de Empleo</h4>
  <Row className="mb-3">
    <Col md={4}>
      <Form.Group controlId="employmentStatus">
        <Form.Label style={{ 
          fontSize: '1rem', 
          fontWeight: 'bold', 
          color: '#003366', // Azul marino para mejor visibilidad
          marginBottom: '0.5rem', // Añade espacio debajo de la etiqueta
          display: 'block' // Asegura que la etiqueta ocupe todo el ancho
        }}>Estado Laboral</Form.Label>
        <Form.Control
          as="select"
          name="employmentStatus"
          value={formData.employmentStatus}
          onChange={handleChange}
          className="custom-select"
          style={{ borderRadius: '4px', boxShadow: '0 1px 3px rgba(0,0,0,0.1)', padding: '10px' }}
        >
          <option value="">Seleccione...</option>
          <option value="Employed">Empleado</option>
          <option value="Self-employed">Trabajador Independiente</option>
          <option value="Unemployed">Desempleado</option>
          <option value="Student">Estudiante</option>
          <option value="Retired">Jubilado</option>
        </Form.Control>
        {formErrors.employmentStatus && <Form.Text className="text-danger">{formErrors.employmentStatus}</Form.Text>}
      </Form.Group>
    </Col>

    {formData.employmentStatus && (formData.employmentStatus !== 'Unemployed' && formData.employmentStatus !== 'Student' && formData.employmentStatus !== 'Retired') && (
      <Col md={4}>
        <Form.Group controlId="occupation">
          <Form.Label style={{ 
            fontSize: '1rem', 
            fontWeight: 'bold', 
            color: '#003366', // Azul marino para mejor visibilidad
            marginBottom: '0.5rem', // Añade espacio debajo de la etiqueta
            display: 'block' // Asegura que la etiqueta ocupe todo el ancho
          }}>Ocupación</Form.Label>
          <Form.Control
            type="text"
            name="occupation"
            value={formData.occupation}
            onChange={handleChange}
            placeholder="Ingeniero de Software"
            isInvalid={!!formErrors.occupation}
            style={{ borderRadius: '4px', boxShadow: '0 1px 3px rgba(0,0,0,0.1)', padding: '10px' }}
          />
          <Form.Control.Feedback type="invalid">{formErrors.occupation}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    )}

    {formData.employmentStatus === 'Self-employed' && (
      <Col md={4}>
        <Form.Group controlId="industry">
          <Form.Label style={{ 
            fontSize: '1rem', 
            fontWeight: 'bold', 
            color: '#003366', // Azul marino para mejor visibilidad
            marginBottom: '0.5rem', // Añade espacio debajo de la etiqueta
            display: 'block' // Asegura que la etiqueta ocupe todo el ancho
          }}>Industria</Form.Label>
          <Form.Control
            as="select"
            name="industry"
            value={formData.industry}
            onChange={handleChange}
            className="custom-select"
            style={{ borderRadius: '4px', boxShadow: '0 1px 3px rgba(0,0,0,0.1)', padding: '10px' }}
          >
            <option value="">Seleccione...</option>
            <option value="Technology">Tecnología</option>
            <option value="Healthcare">Salud</option>
            <option value="Finance">Finanzas</option>
            <option value="Education">Educación</option>
            <option value="Other">Otro</option>
          </Form.Control>
        </Form.Group>
      </Col>
    )}
  </Row>

  {formData.employmentStatus !== 'Unemployed' && formData.employmentStatus !== 'Student' && formData.employmentStatus !== 'Retired' && (
    <Row className="mb-3">
      <Col md={6}>
        <Form.Group controlId="employer">
          <Form.Label style={{ 
            fontSize: '1rem', 
            fontWeight: 'bold', 
            color: '#003366', // Azul marino para mejor visibilidad
            marginBottom: '0.5rem', // Añade espacio debajo de la etiqueta
            display: 'block' // Asegura que la etiqueta ocupe todo el ancho
          }}>Empleador</Form.Label>
          <Form.Control
            type="text"
            name="employer"
            value={formData.employer}
            onChange={handleChange}
            placeholder="ABC Corp."
            style={{ borderRadius: '4px', boxShadow: '0 1px 3px rgba(0,0,0,0.1)', padding: '10px' }}
          />
        </Form.Group>
      </Col>
    </Row>
  )}
</section>


{/* Estado Civil */}
<section style={{ marginBottom: '20px', padding: '20px', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
  <h4 style={{ color: '#003366', borderBottom: '2px solid #003366', paddingBottom: '10px' }}>Estado Civil</h4>
  <Form.Group controlId="maritalStatus">
    <Form.Label style={{ 
      fontSize: '1rem', 
      fontWeight: 'bold', 
      color: '#003366', // Azul marino para mejor visibilidad
      marginBottom: '0.5rem', // Añade espacio debajo de la etiqueta
      display: 'block' // Asegura que la etiqueta ocupe todo el ancho
    }}>Estado Civil</Form.Label>
    <Form.Control
      as="select"
      name="maritalStatus"
      value={formData.maritalStatus}
      onChange={handleChange}
      className="custom-select"
      style={{ borderRadius: '4px', boxShadow: '0 1px 3px rgba(0,0,0,0.1)', padding: '10px' }}
    >
      <option value="">Seleccione...</option>
      <option value="Single">Soltero(a)</option>
      <option value="Married">Casado(a)</option>
      <option value="Divorced">Divorciado(a)</option>
      <option value="Widowed">Viudo(a)</option>
    </Form.Control>
    {formErrors.maritalStatus && <Form.Text className="text-danger">{formErrors.maritalStatus}</Form.Text>}
  </Form.Group>

  {formData.maritalStatus === 'Married' && (
    <Row className="mb-3">
      <Col md={6}>
        <Form.Group controlId="spouseName">
          <Form.Label style={{ 
            fontSize: '1rem', 
            fontWeight: 'bold', 
            color: '#003366', // Azul marino para mejor visibilidad
            marginBottom: '0.5rem', // Añade espacio debajo de la etiqueta
            display: 'block' // Asegura que la etiqueta ocupe todo el ancho
          }}>Nombre del Cónyuge</Form.Label>
          <Form.Control
            type="text"
            name="spouseName"
            value={formData.spouseName}
            onChange={handleChange}
            placeholder="Jane Doe"
            style={{ borderRadius: '4px', boxShadow: '0 1px 3px rgba(0,0,0,0.1)', padding: '10px' }}
          />
        </Form.Group>
      </Col>
    </Row>
  )}

  {formData.maritalStatus === 'Married' && (
    <Row className="mb-3">
      <Col md={6}>
        <Form.Group controlId="children">
          <Form.Label style={{ 
            fontSize: '1rem', 
            fontWeight: 'bold', 
            color: '#003366', // Azul marino para mejor visibilidad
            marginBottom: '0.5rem', // Añade espacio debajo de la etiqueta
            display: 'block' // Asegura que la etiqueta ocupe todo el ancho
          }}>Número de Hijos</Form.Label>
          <Form.Control
            type="number"
            name="children"
            value={formData.children}
            onChange={handleChange}
            placeholder="0"
            style={{ borderRadius: '4px', boxShadow: '0 1px 3px rgba(0,0,0,0.1)', padding: '10px' }}
          />
        </Form.Group>
      </Col>
    </Row>
  )}
</section>

{/* Documentos de Soporte */}
<section style={{ marginBottom: '20px', padding: '20px', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
  <h4 style={{ color: '#003366', borderBottom: '2px solid #003366', paddingBottom: '10px' }}>Documentos de Soporte</h4>
  <Form.Group controlId="supportingDocuments">
    <Form.Label style={{ 
      fontSize: '1rem', 
      fontWeight: 'bold', 
      color: '#003366', // Azul marino para mejor visibilidad
      marginBottom: '0.5rem', // Añade espacio debajo de la etiqueta
      display: 'block' // Asegura que la etiqueta ocupe todo el ancho
    }}>Cargar Documentos de Soporte</Form.Label>
    <Form.Control
      type="file"
      name="supportingDocuments"
      onChange={handleChange}
      style={{ borderRadius: '4px', boxShadow: '0 1px 3px rgba(0,0,0,0.1)', padding: '10px' }}
    />
  </Form.Group>
</section>

{/* Términos y Condiciones */}
<section style={{ marginBottom: '20px', padding: '20px', borderRadius: '8px', backgroundColor: '#f9f9f9', border: '1px solid #ddd' }}>
  <h4 style={{ color: '#003366', borderBottom: '2px solid #003366', paddingBottom: '10px', marginBottom: '15px' }}>Términos y Condiciones</h4>
  
  <Form.Group controlId="agreeTerms" style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
    <Form.Check
      type="checkbox"
      name="agreeTerms"
      label={<span style={{ fontSize: '14px' }}>Acepto los <a href="/terms" style={{ color: '#003366', textDecoration: 'underline' }}>términos y condiciones</a></span>}
      checked={formData.agreeTerms}
      onChange={handleChange}
      isInvalid={!!formErrors.agreeTerms}
      custom
      style={{ fontSize: '14px', margin: '0' }}
    >
      <Form.Check.Input
        type="checkbox"
        style={{ cursor: 'pointer', width: '18px', height: '18px', borderRadius: '4px', border: '1px solid #003366', accentColor: '#003366', marginRight: '10px' }}
      />
      <Form.Check.Label style={{ margin: '0', lineHeight: '18px' }}>
        <span>Acepto los <a href="/terms" style={{ color: '#003366', textDecoration: 'underline' }}>términos y condiciones</a></span>
      </Form.Check.Label>
    </Form.Check>
    <Form.Control.Feedback type="invalid" style={{ fontSize: '12px', marginLeft: '10px' }}>{formErrors.agreeTerms}</Form.Control.Feedback>
  </Form.Group>

  <Form.Group controlId="confirmInfo" style={{ display: 'flex', alignItems: 'center' }}>
    <Form.Check
      type="checkbox"
      name="confirmInfo"
      label={<span style={{ fontSize: '14px' }}>Confirmo que toda la información proporcionada es correcta</span>}
      checked={formData.confirmInfo}
      onChange={handleChange}
      isInvalid={!!formErrors.confirmInfo}
      custom
      style={{ fontSize: '14px', margin: '0' }}
    >
      <Form.Check.Input
        type="checkbox"
        style={{ cursor: 'pointer', width: '18px', height: '18px', borderRadius: '4px', border: '1px solid #003366', accentColor: '#003366', marginRight: '10px' }}
      />
      <Form.Check.Label style={{ margin: '0', lineHeight: '18px' }}>
        <span>Confirmo que toda la información proporcionada es correcta</span>
      </Form.Check.Label>
    </Form.Check>
    <Form.Control.Feedback type="invalid" style={{ fontSize: '12px', marginLeft: '10px' }}>{formErrors.confirmInfo}</Form.Control.Feedback>
  </Form.Group>
</section>



{showSuccessModal && (
  <div className="alert" role="alert" style={{ color: '#155724', backgroundColor: 'transparent', border: 'none', fontSize: '0.875rem' }}>
    <strong>¡Aplicación Guardada!</strong> Por favor, verifica y confirma antes de enviar. <br />
    <small>Envíe cuando toda la información esté completa.</small>
  </div>
)}


<Button
  type="submit"
  variant="primary"
  style={{
    width: '100%',
    padding: '10px 20px',
    fontWeight: '400', // Lighter font weight
    fontSize: '16px', // Adjusted font size
    borderRadius: '4px', // Slightly rounded corners
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' // Light shadow for depth
  }}
>
  Guardar solicitud
</Button>
<Button
  type="button"
  variant="primary"
  href="/payment"
  style={{
    width: '100%',
    padding: '10px 20px',
    fontWeight: '400', // Lighter font weight
    fontSize: '16px', // Adjusted font size
    borderRadius: '4px', // Slightly rounded corners
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Light shadow for depth
    marginTop: '15px', // Distance between buttons
  }}
>
  Enviar y Proceder al Pago
</Button>




</Form>

              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>


     <footer style={{
  backgroundColor: '#003366', // Fondo azul marino
  color: '#ffffff', // Texto blanco
  padding: '20px', // Relleno alrededor del contenido
  textAlign: 'center', // Alinear el texto al centro
  position: 'relative', // Permitir el posicionamiento de elementos internos si es necesario
  bottom: '0', // Fijar al fondo
  width: '100%', // Ancho completo
  borderTop: '1px solid #ffffff', // Opcional: borde superior blanco para separación
}}>
  <div style={{ marginBottom: '10px' }}>
    <h5 style={{ fontSize: '1.25rem', margin: '0' }}>Tarjeta de Beneficios de Ciudadanía</h5>
    <p style={{ fontSize: '0.875rem', margin: '0' }}>Tu fuente confiable para beneficios de ciudadanía.</p>
  </div>
  <div>
    <p style={{ fontSize: '0.75rem', margin: '0' }}>© 2024 Tarjeta de Beneficios de Ciudadanía. Todos los derechos reservados.</p>
  </div>
</footer>


    </div>
  );
};

export default CBCApplicationForm;
