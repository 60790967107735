// src/GovernmentSchemePage.js
import React, { useState } from 'react';
import { Container, Row, Col, Button, Form, Alert, ListGroup, ToggleButtonGroup, ToggleButton, Navbar, Nav, Modal, Image, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import logo from './assets/LogoPagina_Mesa-de-trabajo-1.png'; // Update with the correct path to your logo image
import { benefitsData } from './benefitData'; // Import the benefit data
import cbc from './assets/istockphoto-1384446623-612x612.jpg';
import './App.css';
import exampleImage from './assets/ES_Presidencia_Bukele_meeting_700.jpeg'; // Import the image
import health from './assets/paho-salvador-1.tmb-1920v.jpg'
import insurance from './assets/close-up-doctor-with-stethoscope.jpg'
import grant from './assets/_92350204_claudia111.jpg.webp'
import pension from './assets/240_F_873849906_THxl3gf6UZeOKuRUsH2hPZfO6eOlWKiV.jpg';
import education from './assets/6b8aa79d64f3f98c0fd40585262655ee4e815d89.jpg';
import allowance from './assets/El-Salvador-Youth-Volunteers.jpg';

const MultipleSections = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', description: '', highlight: '' });

  const handleShowModal = (section) => {
    setModalContent(section);
    setShowModal(true);
  };

  const sections = [
    {
      id: 'free-healthcare',
      title: 'Atención Médica Gratuita',
      description: 'Nuestro gobierno ofrece servicios de atención médica gratuita para garantizar que cada ciudadano tenga acceso a una atención médica de calidad. Desde chequeos de rutina hasta tratamientos de emergencia, todos los servicios están cubiertos bajo este programa.',
      bgColor: '#e0f7fa',
      imgSrc: health,
      btnText: 'Saber Más',
      highlight: '¡Servicios médicos 100% gratuitos para todos los ciudadanos!',
    },
    {
      id: 'health-insurance',
      title: 'Seguro de Salud',
      description: 'Proporcionamos planes de seguro de salud integrales que cubren una amplia gama de servicios médicos, incluyendo hospitalización, cirugías y consultas con especialistas. Inscríbete hoy para asegurar tu salud y bienestar. Nuestros planes ofrecen una cobertura de hasta $50,000 USD.',
      bgColor: '#f0e0fa',
      imgSrc: insurance,
      btnText: 'Saber Más',
      highlight: '¡Seguro asequible con amplia cobertura de hasta $50,000 USD!',
    },
    
    {
      id: 'business-grant',
      title: 'Subvención para Negocios',
      description: 'Nuestro programa de subvenciones para negocios apoya a emprendedores y pequeñas empresas con asistencia financiera para ayudarlos a crecer y prosperar. Solicita ahora para obtener los fondos que necesitas para llevar tu negocio al siguiente nivel.',
      bgColor: '#fae0e0',
      imgSrc: grant,
      btnText: 'Saber Más',
      highlight: '¡Hasta $50,000 en subvenciones para negocios elegibles!',
    },
    {
      id: 'pension',
      title: 'Pensión para Desempleados y Ancianos',
      description: 'Proporcionamos apoyo financiero a personas desempleadas y ancianos a través de nuestro programa de pensiones. Esta iniciativa asegura que todos puedan vivir con dignidad y seguridad financiera.',
      bgColor: '#e0fae0',
      imgSrc: pension,
      btnText: 'Saber Más',
      highlight: '¡Pensiones mensuales para desempleados y ancianos!',
    },
    {
      id: 'free-education',
      title: 'Educación Gratuita',
      description: 'Nuestro gobierno ofrece programas de educación gratuita para todos los ciudadanos. Desde la escuela primaria hasta la universidad, nos aseguramos de que todos tengan la oportunidad de aprender y crecer sin barreras financieras.',
      bgColor: '#faf0e0',
      imgSrc: education,
      btnText: 'Saber Más',
      highlight: '¡Educación gratuita para todos los grupos de edad!',
    },
    {
      id: 'unemployment-allowance',
      title: 'Subsidio de Desempleo',
      description: 'Nuestro programa de subsidio de desempleo proporciona asistencia financiera a las personas que están desempleadas. Recibe una asignación mensual de $250 para ayudarte durante tu búsqueda de empleo.',
      bgColor: '#e0e0fa',
      imgSrc: allowance,
      btnText: 'Saber Más',
      highlight: '¡Recibe $250 mensuales para personas desempleadas!',
    },

  ];

  return (
    <>
      {sections.map((section) => (
        <Container fluid className="my-5" id={section.id} style={{ backgroundColor: section.bgColor }} key={section.id}>
          <Row className="g-4">
            <Col md={6} className="d-flex align-items-center">
              <Image src={section.imgSrc} fluid className="w-100 rounded-3 shadow-lg" />
            </Col>
            <Col md={6} className="d-flex flex-column justify-content-center p-4 p-md-5">
              <h2 className="display-4 fw-bold mb-3" style={{ color: 'rgb(2, 35, 95)' }}>
                {section.title}
              </h2>
              <p className="lead mb-4">{section.description}</p>
              <div className="highlight bg-warning text-dark p-3 mb-4 rounded">
                <strong>{section.highlight}</strong>
              </div>
              <Button
                variant="primary"
                className="w-100 rounded-pill py-3"
                style={{ backgroundColor: 'rgb(2, 35, 95)' }}
                onClick={() => handleShowModal(section)}
              >
                {section.btnText}
              </Button>
            </Col>
          </Row>
        </Container>
      ))}

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{modalContent.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{modalContent.description}</p>
          <div className="highlight bg-warning text-dark p-3 mb-4 rounded">
            <strong>{modalContent.highlight}</strong>
          </div>
          <p>By obtaining a CBC card, you can access these benefits easily and efficiently. The CBC card ensures that you are recognized as a beneficiary of our programs and allows you to receive the support you need quickly. Enroll now to start enjoying these benefits!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};


const BenefitsSection = ({ benefitsData, handleShow }) => {
  const { t } = useTranslation();

  return (
    <Container fluid className="my-5" id="cbc-benefits">
      <Row className="g-4 justify-content-center">
        <Col md={10}>
          <div 
            className="p-5 rounded-4 shadow-lg"
            style={{ backgroundColor: 'white', color: 'rgb(2, 35, 95)' }}
          >
            <h2 
              className="display-4 text-center mb-4"
              style={{ color: 'rgb(2, 35, 95)' }}
            >
              {t('availableBenefits')}
            </h2>
            <ListGroup>
              {benefitsData.map((benefit) => (
                <ListGroup.Item
                  key={benefit.id}
                  className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center border-0 bg-light mb-3 rounded-3 p-3"
                  style={{ backgroundColor: '#f8f9fa' }}
                >
                  <div className="mb-3 mb-md-0">
                    <h5 className="mb-2" style={{ color: 'rgb(2, 35, 95)' }}>
                      {t(benefit.title)}
                    </h5>
                    <p className="mb-0 text-muted">
                      {t(benefit.description).substring(0, 100)}...
                    </p>
                  </div>
                  <Button
                    variant="outline-primary"
                    onClick={() => handleShow(benefit)}
                    className="rounded-pill mt-2 mt-md-0"
                    style={{ borderColor: 'rgb(2, 35, 95)', color: 'rgb(2, 35, 95)' }}
                  >
                    Learn More
                  </Button>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

// Apply Now Section
const ApplyNowSection = () => {
  const { t } = useTranslation();

  return (
    <Container fluid className="my-5 text-center" id="apply-now">
      <Row className="justify-content-center">
        <Col xs={12} md={10} lg={8}>
          <Card className="border-0 shadow-lg rounded-4">
            <Card.Body className="p-5">
              <Card.Title as="h2" className="display-4 mb-3">
                {t('cbcCardApplyTitle')}
              </Card.Title>
              <Card.Text className="lead mb-4">
                {t('cbcCardApplyDescription')}
              </Card.Text>
              <Button
  variant="success"
  href="/application"
  className="w-20 rounded-pill py-2 py-md-3"
  style={{ backgroundColor: 'rgb(2, 35, 95)', borderColor: 'rgb(2, 35, 95)' }}
>
  {t('cbcCardApplyButton')}
</Button>

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

// Main Page Component
const GovernmentSchemePage = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentBenefit, setCurrentBenefit] = useState(null);
  const { t, i18n } = useTranslation();
  const [showAlert, setShowAlert] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    phone: '',
    nationalId: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowAlert(true);
  };

  const toggleLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const handleShow = (benefit) => {
    setCurrentBenefit(benefit);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentBenefit(null);
  };
  const sectionStyle = {
    position: 'relative',
    height: '400px', // Adjust height as needed
    overflow: 'hidden',
  };

  const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  const overlayStyle = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    textAlign: 'center',
    padding: '20px',
    boxSizing: 'border-box',
  };

  const headingStyle = {
    marginBottom: '10px',
  };

  const paragraphStyle = {
    fontSize: '1.25rem',
  };
  return (
    <div>
<Navbar bg="dark" variant="dark" expand="lg" className="border-bottom">
  <Container>
    <Navbar.Brand href="#home" className="d-flex align-items-center">
      <img
        src={logo}
        alt="Gobierno de El Salvador Logo"
        className="me-2"
        style={{ height: '50px', width: 'auto' }} // Adjust logo size for responsiveness
      />
      <span className="fs-4 fw-bold text-light">Gobierno de El Salvador</span>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto">
        <Nav.Link href="#cbc-benefits" className="fs-5 text-light">Beneficios CBC</Nav.Link>
        <Nav.Link href="/application" className="fs-5 text-light">Formulario de Aplicación</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>


      {/* Hero Section */}
      <div className="hero text-center text-white py-5" style={{ backgroundColor: '#02235f' }}>
        <Container>
          <h1 className="display-3">{t('title')}</h1>
          <p className="lead">{t('intro')}</p>
        </Container>
      </div>

    <Container fluid style={{ padding: '0' }}>
      <div style={sectionStyle}>
        <img src={exampleImage} alt="Beautiful scenery" style={imageStyle} />
        <div style={overlayStyle}>
          <div>
          <h2 style={headingStyle}>Descubre tus Beneficios</h2>
            <p style={paragraphStyle}>
              Explora los diversos beneficios disponibles para ti y aprovecha al máximo las oportunidades. Descubre los programas diseñados para asistirte y mejorar tu calidad de vida.
            </p>
          </div>
        </div>
      </div>
    </Container>
   <ApplyNowSection />

     {/* CBC Citizens Benefits Section */}
     <Container fluid className="my-5" id="cbc-benefits" style={{ backgroundColor: '#02235f' }}>
  <Row className="g-4">
    {/* Image Column */}
    <Col xs={12} md={6} className="d-flex align-items-center">
      <Image src={cbc} fluid className="w-100 rounded-3 shadow-lg" />
    </Col>

    {/* Benefits Column */}
    <Col xs={12} md={6} className="d-flex flex-column justify-content-center p-4 p-md-5 text-white">
      <div className="text-center text-md-start mb-5">
        <h2 className="display-4 fw-bold mb-3">{t('cbcBenefitsTitle')}</h2>
        <p className="lead mb-4">{t('cbcBenefitsSubtitle')}</p>
      </div>
      <p className="mb-4">{t('cbcBenefitsIntroduction')}</p>
      <p className="mb-4">{t('cbcBenefitsDetailsIntro')}</p>
      <ul className="list-unstyled mb-4">
        <li className="mb-3">
          <strong>{t('financialAssistanceTitle')}:</strong> {t('financialAssistanceDescription')}
        </li>
        <li className="mb-3">
          <strong>{t('healthcareBenefitsTitle')}:</strong> {t('healthcareBenefitsDescription')}
        </li>
        <li className="mb-3">
          <strong>{t('educationalSupportTitle')}:</strong> {t('educationalSupportDescription')}
        </li>
        <li className="mb-3">
          <strong>{t('discountsOffersTitle')}:</strong> {t('discountsOffersDescription')}
        </li>
        <li className="mb-3">
          <strong>{t('priorityAccessTitle')}:</strong> {t('priorityAccessDescription')}
        </li>
        <li className="mb-3">
          <strong>{t('smallBusinessFundingTitle')}:</strong> {t('smallBusinessFundingDescription')}
          <span className="text-success bg-white fw-bold"> {t('smallBusinessFundingAmount')}</span>
          </li>
      </ul>
      <p className="mb-4">
        {t('learnMoreIntro')}{' '}
        <a href="#apply" className="text-info fw-bold">{t('applicationFormLink')}</a>.
      </p>
      <Button variant="primary" href="#apply" className="w-100 rounded-pill py-3">
        {t('learnMoreButton')}
      </Button>
    </Col>
  </Row>
</Container>

<MultipleSections />
<Container fluid className="my-5" id="go-to-application" style={{ backgroundColor: 'rgb(2, 35, 95)', color: 'white' }}>
  <Row className="justify-content-center text-center py-5">
    <Col xs={12} md={8} lg={6}>
      <div className="p-4 rounded-4 shadow-lg">
        <h2 className="display-4 mb-4">aplicar aquí</h2>
        <p className="lead mb-4">
          Comienza tu solicitud hoy y da el primer paso hacia la obtención de tus beneficios. 
          Nuestro formulario de aplicación fácil de usar está diseñado para ayudarte en cada paso del proceso.
        </p>
        <Button
          variant="light"
          href="/application"
          className="rounded-pill px-4 py-2"
          style={{ backgroundColor: 'white', color: 'rgb(2, 35, 95)', fontWeight: 'bold' }}
        >
          Ir a la Aplicación
        </Button>
      </div>
    </Col>
  </Row>
</Container>
<BenefitsSection benefitsData={benefitsData} handleShow={handleShow} />
      {/* Modals for Detailed Benefit Information */}
      {currentBenefit && (
        <Modal show={showModal} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{t(currentBenefit.title)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Image src={currentBenefit.image} fluid className="mb-3 rounded" />
            <p>{t(currentBenefit.description)}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
 

<footer className="bg-dark text-light py-4">
  <Container>
    <Row className="mb-4">
      <Col md={4} className="d-flex align-items-center">
        <img
          src={logo}
          alt="Gobierno de El Salvador Logo"
          className="me-3"
          style={{ height: '60px', width: 'auto' }}
        />
        <h5 className="mb-0">Presidencia de la República de El Salvador</h5>
      </Col>
      <Col md={8}>
        <h5 className="mb-3">Contáctenos</h5>
        <p className="mb-1">
          <strong>Dirección:</strong> Alameda Doctor Manuel Enrique Araujo No 5500, San Salvador, El Salvador, C.A.
        </p>
        <p className="mb-1">
          <strong>Escríbenos:</strong> <a href="mailto:contacto@presidencia.gob.sv" className="text-light">contacto@presidencia.gob.sv</a>
        </p>
        <p className="mb-0">
          <strong>Llámanos:</strong> <a href="tel:+50322489000" className="text-light">(503) 2248-9000</a>
        </p>
      </Col>
    </Row>
    <Row className="mb-4">
      <Col className="text-center">
        <h5 className="mb-3">Síguenos</h5>
        <a href="#" className="text-light me-3" aria-label="Facebook"><i className="fab fa-facebook-f"></i></a>
        <a href="#" className="text-light me-3" aria-label="Twitter"><i className="fab fa-twitter"></i></a>
        <a href="#" className="text-light me-3" aria-label="Instagram"><i className="fab fa-instagram"></i></a>
        <a href="#" className="text-light" aria-label="LinkedIn"><i className="fab fa-linkedin-in"></i></a>
      </Col>
    </Row>
    <Row>
      <Col className="text-center">
        <p className="mb-0">&copy; {new Date().getFullYear()} Presidencia de la República de El Salvador. Todos los derechos reservados.</p>
      </Col>
    </Row>
  </Container>
</footer>



    </div>
  );
};

export default GovernmentSchemePage;
